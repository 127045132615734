
import CurrHistTextarea from '@/components/CurrHistTextarea.vue'
import Patient from '@/models/Patient'
import SimpleQuestion from '@/components/SimpleQuestion.vue'
import Swal from 'sweetalert2'
import YesNoCurrHistRadio from '@/components/YesNoCurrHistRadio.vue'
import { Options, Vue } from 'vue-class-component'
import { mapActions, mapGetters } from 'vuex'

@Options({
  components: {
    YesNoCurrHistRadio,
    CurrHistTextarea,
    SimpleQuestion
  },
  methods: mapActions([
    'updateInterventionAnamnesis',
    'downloadPsychotherapeuticInterventions'
  ]),
  computed: mapGetters(['patient', 'intervention_anamnesis'])
})
export default class PsychotherapeuticInterventions extends Vue {
  patient!: Patient
  // eslint-disable-next-line camelcase
  intervention_anamnesis!: Patient['intervention_anamnesis']

  // Actions
  updateInterventionAnamnesis!: (payload: {
    id: number
    intAnamnesis: Patient['intervention_anamnesis']
  }) => Promise<void>

  downloadPsychotherapeuticInterventions!: () => Promise<void>

  created (): void {
    this.downloadPsychotherapeuticInterventions()
  }

  submit (): void {
    this.updateInterventionAnamnesis({
      id: this.patient.id,
      intAnamnesis: this.intervention_anamnesis
    })
      .then(() => {
        Swal.fire({
          title: this.$t('PsychotherapeuticInterventions.UpdateTitle'),
          confirmButtonText: this.$t(
            'PsychotherapeuticInterventions.UpdateConfirmButton'
          )
        }).then(() => {
          this.$router.push({ name: this.$route.meta.next })
        })
      })
      .catch(() => {
        this.$router.push({ name: 'Logout' })
      })
  }
}
