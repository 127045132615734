
import { Options, Vue } from 'vue-class-component'
import YesNoRadio from '@/components/YesNoRadio.vue'

@Options({
  props: {
    value: Boolean,
    text: String,
    subQuestionLvl: Number
  },
  components: {
    YesNoRadio
  }
})
export default class SimpleQuestion extends Vue {
  value!: boolean
  subQuestionLvl!: number

  emit (val: boolean): void {
    this.$emit('changed', val)
    this.$emit('change')
  }
}
