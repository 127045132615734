
import { Options, Vue } from 'vue-class-component'
import YesNoRadio from '@/components/YesNoRadio.vue'

@Options({
  components: {
    YesNoRadio
  },
  props: {
    question: String,
    dataPresent: Boolean,
    dataHistory: Boolean,
    hidePresent: Boolean,
    hideHistory: Boolean,
    subQuestionLvl: Number
  }
})
export default class YesNoCurrHistRadio extends Vue {
  question!: string
  dataPresent!: boolean
  dataHistory!: boolean

  hidePresent!: boolean
  hideHistory!: boolean

  subQuestionLvl!: number

  emitPresent (val: boolean): void {
    this.$emit('yesNoPresentEvent', val)
    this.$emit('change')
  }

  emitHistory (val: boolean): void {
    this.$emit('yesNoHistoryEvent', val)
    this.$emit('change')
  }
}
