import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200" }
const _hoisted_2 = { class: "grid grid-cols-1 sm:grid-cols-2 sm:col-span-2 flex h-full" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col items-center py-4 gap-2"
}
const _hoisted_4 = { class: "visible sm:hidden text-sm font-medium text-gray-700 sm:py-5" }
const _hoisted_5 = {
  key: 0,
  class: "flex flex-col items-center py-4 gap-2"
}
const _hoisted_6 = { class: "visible sm:hidden text-sm font-medium text-gray-700 sm:py-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_yesNoRadio = _resolveComponent("yesNoRadio")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass([{
        'sm:pl-8': _ctx.subQuestionLvl === 1,
        'sm:pl-10': _ctx.subQuestionLvl === 2,
        'sm:pl-12': _ctx.subQuestionLvl === 3,
        'sm:pl-14': _ctx.subQuestionLvl === 4,
        'bg-gray-50': _ctx.subQuestionLvl === 1,
        'bg-gray-100': _ctx.subQuestionLvl === 2,
        'bg-gray-200': _ctx.subQuestionLvl === 3,
        'bg-gray-300': _ctx.subQuestionLvl === 4
      }, "block h-full text-sm font-medium text-gray-700 p-4 sm:py-5 flex flex-col justify-center imtes-center"])
    }, _toDisplayString(_ctx.question), 3),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass([{
          'bg-gray-300': _ctx.subQuestionLvl === 1,
          'bg-gray-400': _ctx.subQuestionLvl === 2,
          'bg-gray-500': _ctx.subQuestionLvl === 3,
          'bg-gray-600': _ctx.subQuestionLvl === 4
        }, "flex flex-col justify-center bg-gray-200"])
      }, [
        _createVNode(_Transition, {
          "enter-active-class": "animate__animated animate__fadeIn",
          "leave-active-class": "animate__animated animate__fadeOut"
        }, {
          default: _withCtx(() => [
            (!_ctx.hidePresent)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('YesNoCurrHistRadio.Present')), 1),
                  _createVNode(_component_yesNoRadio, {
                    value: _ctx.dataPresent,
                    onYesnoevent: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitPresent($event)))
                  }, null, 8, ["value"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass([{
          'bg-gray-300': _ctx.subQuestionLvl === null,
          'bg-gray-400': _ctx.subQuestionLvl === 1,
          'bg-gray-500': _ctx.subQuestionLvl === 2
        }, "flex flex-col justify-center bg-gray-300"])
      }, [
        _createVNode(_Transition, {
          "enter-active-class": "animate__animated animate__fadeIn",
          "leave-active-class": "animate__animated animate__fadeOut"
        }, {
          default: _withCtx(() => [
            (!_ctx.hideHistory)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('YesNoCurrHistRadio.History')), 1),
                  _createVNode(_component_yesNoRadio, {
                    value: _ctx.dataHistory,
                    onYesnoevent: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emitHistory($event)))
                  }, null, 8, ["value"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ], 2)
    ])
  ]))
}