
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    value: Boolean
  }
})
export default class YesNoRadio extends Vue {
  value!: boolean
  mutableValue!: boolean

  /**
   * Work arrownd for rerendering the buttons with :key atribute.
   * All the info: https://michaelnthiessen.com/force-re-render/
   */
  rerender = 0

  created (): void {
    this.mutableValue = this.value
  }

  mounted (): void {
    if (this.mutableValue === null) this.emit(false)
  }

  emit (val: boolean): void {
    this.mutableValue = val
    this.rerender += 1
    this.$emit('yesnoevent', this.mutableValue)
    this.$emit('change')
  }
}
