
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    question: String,
    dataCurrent: String,
    dataHistory: String,
    hideCurrent: Boolean,
    hideHistory: Boolean,
    subQuestionLvl: Number
  }
})
export default class CurrHistTextarea extends Vue {
  question!: string
  dataCurrent!: string
  dataHistory!: string

  mutableCurrent!: string
  mutableHistory!: string

  hideCurrent!: boolean
  hideHistory!: boolean

  subQuestionLvl!: number

  created (): void {
    this.mutableCurrent = this.dataCurrent
    this.mutableHistory = this.dataHistory
  }

  currChanged (): void {
    this.$emit('currChanged', this.mutableCurrent)
    this.$emit('changed')
  }

  histChanged (): void {
    this.$emit('histChanged', this.mutableHistory)
    this.$emit('changed')
  }
}
