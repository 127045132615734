import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative z-0 inline-flex shadow-sm rounded-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (_openBlock(), _createElementBlock("button", {
      type: "button",
      class: _normalizeClass([{ 'bg-blue-300': _ctx.mutableValue }, "relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-blue-200 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emit(true))),
      key: _ctx.rerender
    }, _toDisplayString(_ctx.$t('YesNoRadio.Yes')), 3)),
    (_openBlock(), _createElementBlock("button", {
      type: "button",
      class: _normalizeClass([{ 'bg-blue-200': !_ctx.mutableValue }, "-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-blue-200 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emit(false))),
      key: _ctx.rerender
    }, _toDisplayString(_ctx.$t('YesNoRadio.No')), 3))
  ]))
}