import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center bg-white rounded-lg p-8 border-2 border-gray-300" }
const _hoisted_2 = { class: "space-y-8 divide-y divide-gray-200 sm:space-y-5" }
const _hoisted_3 = { class: "text-lg leading-6 font-medium text-gray-900" }
const _hoisted_4 = { class: "mt-1 max-w-2xl text-sm text-gray-500" }
const _hoisted_5 = { class: "flex flex-col gap-4 sm:gap-0" }
const _hoisted_6 = { class: "pt-5" }
const _hoisted_7 = { class: "flex justify-end" }
const _hoisted_8 = {
  type: "submit",
  class: "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleQuestion = _resolveComponent("SimpleQuestion")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      class: "space-y-8 divide-y divide-gray-200",
      onSubmit: _cache[11] || (_cache[11] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('PsychotherapeuticInterventions.Title')), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('PsychotherapeuticInterventions.PersonalPatientInfo')), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: 
                _ctx.$t('PsychotherapeuticInterventions.current_adhd_psychotherapy')
              ,
              value: _ctx.intervention_anamnesis.current_adhd_psychotherapy,
              key: _ctx.intervention_anamnesis.current_adhd_psychotherapy,
              onChanged: _cache[0] || (_cache[0] = ($event: any) => (
                _ctx.intervention_anamnesis.current_adhd_psychotherapy = $event
              ))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: 
                _ctx.$t('PsychotherapeuticInterventions.start_adhd_psychotherapy')
              ,
              value: _ctx.intervention_anamnesis.start_adhd_psychotherapy,
              key: _ctx.intervention_anamnesis.start_adhd_psychotherapy,
              onChanged: _cache[1] || (_cache[1] = ($event: any) => (
                _ctx.intervention_anamnesis.start_adhd_psychotherapy = $event
              ))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: 
                _ctx.$t(
                  'PsychotherapeuticInterventions.current_anxiety_disorder_psychotherapy'
                )
              ,
              value: 
                _ctx.intervention_anamnesis.current_anxiety_disorder_psychotherapy
              ,
              key: 
                _ctx.intervention_anamnesis.current_anxiety_disorder_psychotherapy
              ,
              onChanged: _cache[2] || (_cache[2] = ($event: any) => (
                _ctx.intervention_anamnesis.current_anxiety_disorder_psychotherapy =
                  $event
              ))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: 
                _ctx.$t(
                  'PsychotherapeuticInterventions.start_anxiety_disorder_psychotherapy'
                )
              ,
              value: 
                _ctx.intervention_anamnesis.start_anxiety_disorder_psychotherapy
              ,
              key: _ctx.intervention_anamnesis.start_anxiety_disorder_psychotherapy,
              onChanged: _cache[3] || (_cache[3] = ($event: any) => (
                _ctx.intervention_anamnesis.start_anxiety_disorder_psychotherapy =
                  $event
              ))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: 
                _ctx.$t(
                  'PsychotherapeuticInterventions.current_depressive_disorder_psychotherapy'
                )
              ,
              value: 
                _ctx.intervention_anamnesis.current_depressive_disorder_psychotherapy
              ,
              key: 
                _ctx.intervention_anamnesis.current_depressive_disorder_psychotherapy
              ,
              onChanged: _cache[4] || (_cache[4] = ($event: any) => (
                _ctx.intervention_anamnesis.current_depressive_disorder_psychotherapy =
                  $event
              ))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: 
                _ctx.$t(
                  'PsychotherapeuticInterventions.start_depressive_disorder_psychotherapy'
                )
              ,
              value: 
                _ctx.intervention_anamnesis.start_depressive_disorder_psychotherapy
              ,
              key: 
                _ctx.intervention_anamnesis.start_depressive_disorder_psychotherapy
              ,
              onChanged: _cache[5] || (_cache[5] = ($event: any) => (
                _ctx.intervention_anamnesis.start_depressive_disorder_psychotherapy =
                  $event
              ))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: 
                _ctx.$t('PsychotherapeuticInterventions.current_sud_psychotherapy')
              ,
              value: _ctx.intervention_anamnesis.current_sud_psychotherapy,
              key: _ctx.intervention_anamnesis.current_sud_psychotherapy,
              onChanged: _cache[6] || (_cache[6] = ($event: any) => (
                _ctx.intervention_anamnesis.current_sud_psychotherapy = $event
              ))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: 
                _ctx.$t('PsychotherapeuticInterventions.start_sud_psychotherapy')
              ,
              value: _ctx.intervention_anamnesis.start_sud_psychotherapy,
              key: _ctx.intervention_anamnesis.start_sud_psychotherapy,
              onChanged: _cache[7] || (_cache[7] = ($event: any) => (_ctx.intervention_anamnesis.start_sud_psychotherapy = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: 
                _ctx.$t(
                  'PsychotherapeuticInterventions.current_other_disorder_psychotherapy'
                )
              ,
              value: 
                _ctx.intervention_anamnesis.current_other_disorder_psychotherapy
              ,
              key: _ctx.intervention_anamnesis.current_other_disorder_psychotherapy,
              onChanged: _cache[8] || (_cache[8] = ($event: any) => (
                _ctx.intervention_anamnesis.current_other_disorder_psychotherapy =
                  $event
              ))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: 
                _ctx.$t(
                  'PsychotherapeuticInterventions.start_other_disorder_psychotherapy'
                )
              ,
              value: _ctx.intervention_anamnesis.start_other_disorder_psychotherapy,
              key: _ctx.intervention_anamnesis.start_other_disorder_psychotherapy,
              onChanged: _cache[9] || (_cache[9] = ($event: any) => (
                _ctx.intervention_anamnesis.start_other_disorder_psychotherapy =
                  $event
              ))
            }, null, 8, ["text", "value"]))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$router.back())),
            type: "button",
            class: "bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          }, _toDisplayString(_ctx.$t('PsychotherapeuticInterventions.Back')), 1),
          _createElementVNode("button", _hoisted_8, _toDisplayString(_ctx.$t('PsychotherapeuticInterventions.Save')), 1)
        ])
      ])
    ], 32)
  ]))
}