import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200" }
const _hoisted_2 = { class: "grid grid-cols-1 sm:grid-cols-2 sm:col-span-2 flex h-full" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col items-center p-4 gap-2"
}
const _hoisted_4 = {
  for: "curr",
  class: "visible sm:hidden text-sm font-medium text-gray-700 sm:py-5"
}
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = {
  key: 0,
  class: "flex flex-col items-center p-4 gap-2"
}
const _hoisted_7 = {
  for: "hist",
  class: "visible sm:hidden text-sm font-medium text-gray-700 sm:py-5"
}
const _hoisted_8 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass([{
        'sm:pl-8': _ctx.subQuestionLvl === 1,
        'sm:pl-10': _ctx.subQuestionLvl === 2,
        'sm:pl-12': _ctx.subQuestionLvl === 3,
        'sm:pl-14': _ctx.subQuestionLvl === 4,
        'bg-gray-50': _ctx.subQuestionLvl === 1,
        'bg-gray-100': _ctx.subQuestionLvl === 2,
        'bg-gray-200': _ctx.subQuestionLvl === 3,
        'bg-gray-300': _ctx.subQuestionLvl === 4
      }, "block h-full text-sm font-medium text-gray-700 p-4 sm:py-5 flex flex-col justify-center imtes-center"])
    }, null, 2),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass([{
          'bg-gray-300': _ctx.subQuestionLvl === 1,
          'bg-gray-400': _ctx.subQuestionLvl === 2,
          'bg-gray-500': _ctx.subQuestionLvl === 3,
          'bg-gray-600': _ctx.subQuestionLvl === 4
        }, "flex flex-col justify-center bg-gray-200"])
      }, [
        _createVNode(_Transition, {
          "enter-active-class": "animate__animated animate__fadeIn",
          "leave-active-class": "animate__animated animate__fadeOut"
        }, {
          default: _withCtx(() => [
            (!_ctx.hideCurrent)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('CurrHistTextarea.Present')), 1),
                  _withDirectives(_createElementVNode("textarea", {
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mutableCurrent) = $event)),
                    id: "curr",
                    name: "curr",
                    rows: "3",
                    class: "max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md",
                    placeholder: _ctx.$t('CurrHistTextarea.PresentInputPlaceholder'),
                    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.currChanged()))
                  }, "\r\n            ", 40, _hoisted_5), [
                    [_vModelText, _ctx.mutableCurrent]
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass([{
          'bg-gray-300': _ctx.subQuestionLvl === null,
          'bg-gray-400': _ctx.subQuestionLvl === 1,
          'bg-gray-500': _ctx.subQuestionLvl === 2
        }, "flex flex-col justify-center bg-gray-300"])
      }, [
        _createVNode(_Transition, {
          "enter-active-class": "animate__animated animate__fadeIn",
          "leave-active-class": "animate__animated animate__fadeOut"
        }, {
          default: _withCtx(() => [
            (!_ctx.hideHistory)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('CurrHistTextarea.History')), 1),
                  _withDirectives(_createElementVNode("textarea", {
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.mutableHistory) = $event)),
                    id: "hist",
                    name: "hist",
                    rows: "3",
                    class: "max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md",
                    placeholder: _ctx.$t('CurrHistTextarea.HistoryInputPlaceholder'),
                    onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.histChanged()))
                  }, "\r\n            ", 40, _hoisted_8), [
                    [_vModelText, _ctx.mutableHistory]
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ], 2)
    ])
  ]))
}